import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SERVER_API_URL} from 'app/app.constants';
import {IBusinessType, BusinessType} from 'app/blocks/model/business-type.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BusinessTypeService extends EntityCrudService<IBusinessType> {
    constructor(http: HttpClient) {
        super(http, Resource.BUSINESS_TYPES, (obj?) => new BusinessType(obj));
    }

    public getLite = (): Promise<BusinessType[]> => {
        return this.http.get<any[]>(`${SERVER_API_URL}api/business-types-lite`).toPromise();
    };

    public updateBusinessTypeOfOrder(orderId: number, businessTypeId: IBusinessType): Observable<void> {
        const params = new HttpParams().set('businessTypeId', businessTypeId.toString());
        return this.http.post<void>(`${SERVER_API_URL}api/haulier-orders/${orderId}/update-business-type`, null, {params});
    }
}
