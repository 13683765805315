import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    Inject,
    Input,
    OnInit,
    Optional,
    Output
} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import {TRANSLATION_BASE} from 'app/constants/injection-tokens';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {DiFormConfigDirective} from 'app/common/di-forms/directives/di-form.directive';
import {DiFormElement} from 'app/common/di-forms/shared/di-form-element';
import {ViewChild, HostListener} from '@angular/core';
import {NzSelectComponent} from 'ng-zorro-antd/select';
import {RoleService} from 'app/blocks/service/api/role.service';
import {LogService} from 'app/blocks/service/log.service';
import {debounce} from 'lodash';

export const formElementProvider: any = {
    provide: DiFormElement,
    useExisting: forwardRef(() => DiSelectComponent)
};

@Component({
    selector: 'di-select',
    template: `
        <nz-form-item [formGroup]="thisFormGroup" [ngStyle]="{'justify-content': getPosition()}">
            <div [ngStyle]="{width: width}">
                <nz-form-label *ngIf="!suppressLabel" [nzFor]="fieldLabel$ | async" [nzRequired]="fieldIsRequired" [nzNoColon]="true">
                    <span [ngClass]="field">
                        {{ label || (fieldLabel$ | async) }}
                        <fa-icon *ngIf="fieldHint$ | async as hint" [icon]="faInfoIcon" size="xs" nz-tooltip [nzTooltipTitle]="hint"></fa-icon>
                    </span>
                </nz-form-label>
                <nz-form-control [nzErrorTip]="suppressError ? null : (validationError$ | async)">
                    <nz-input-group>
                        <nz-select
                            #selectElement
                            id="{{ 'di-select-' + field + idSuffix }}"
                            [nzDisabled]="readonly"
                            [ngStyle]="{width: width, 'max-width': width}"
                            [nzAutoFocus]="focus"
                            [nzMode]="multiple ? 'multiple' : 'default'"
                            formControlName="{{ field }}"
                            [compareWith]="compareFn"
                            [nzAllowClear]="true"
                            (ngModelChange)="onChange($event)"
                            (nzOpenChange)="registerOpen($event)"
                            [nzPlaceHolder]="placeholder"
                            [nzShowSearch]="true">
                            <nz-option
                                *ngFor="let option of options$ | async"
                                [nzValue]="getValue(option)"
                                [nzLabel]="getLabel(option)"
                                [nzDisabled]="isDisabled(option)">
                            </nz-option>
                        </nz-select>
                    </nz-input-group>
                </nz-form-control>
            </div>
        </nz-form-item>
    `,
    styleUrls: ['./di-select.component.scss'],
    providers: [formElementProvider],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiSelectComponent extends DiFormElement implements OnInit {
    @ViewChild(NzSelectComponent, {static: true})
    nzSelectElement: NzSelectComponent;

    @Input() @HostBinding('class.flex-grow') grow = true;
    @Input() width = '100%';
    @Input() align: 'start' | 'center' | 'end' = 'start';
    @Input() focus = false;
    @Input() idSuffix = '';
    @Input() field: string;
    @Input() options$: Observable<any>;
    @Input() displayAttribute = 'id';
    @Input() valueAttribute: string | null;
    @Input() controlValueAttribute: string | null;
    @Input() preSelectFirstOption = true;
    @Input() suppressLabel = false;
    @Input() suppressError = false;
    @Input() multiple = false;
    @Input() compareFn: (item1, item2) => boolean;
    @Input() displayFn: (value: any) => string;
    @Input() disabledFn: (value: any) => boolean;
    @Input() readonly = false;
    @Input() initialValue: any;
    @Input() placeholder = 'Choose';
    @Input() roleDependent = false;
    @Input() showField = true;
    @Input() roleSettingSuffix = '';
    @Input() label: string;
    @Input() enableTracking = false;

    @Output() selectionChange = new EventEmitter<any>();
    @Output() tabPress = new EventEmitter<any>();

    faInfoIcon = faInfoCircle;
    private _defaultValue: any;

    constructor(
        @Inject(TRANSLATION_BASE) translationBase,
        translateService: TranslateService,
        fgDirective: FormGroupDirective,
        @Optional() diForm: DiFormConfigDirective,
        private changeDetectorRef: ChangeDetectorRef,
        protected roleService: RoleService,
        private _logService: LogService
    ) {
        super(translationBase, translateService, fgDirective, roleService, diForm);
    }

    @HostListener('keydown.Tab', ['$event'])
    onTabPress($event: KeyboardEvent): void {
        this.tabPress.emit($event);
    }

    focusNow(): void {
        if (this.nzSelectElement) {
            this.nzSelectElement.focus();
        }
    }

    registerOpen(opened: Event): void {
        if (!opened) {
            // i.e. drop down was closed
            setTimeout(() => this.focusNow(), 0);
        }
    }

    getPosition(): string {
        if (this.align !== 'center') {
            return 'flex-' + this.align;
        }
        return this.align;
    }

    getLabel(option: any): string {
        if (this.displayFn) {
            return this.displayFn(option);
        } else {
            return this.displayAttribute ? option[this.displayAttribute] : option;
        }
    }

    getValue(option: any): string {
        if (this.controlValueAttribute) {
            return option[this.controlValueAttribute];
        }
        return this.valueAttribute ? option[this.valueAttribute] : option;
    }

    isDisabled(option: any): boolean {
        if (this.disabledFn) {
            return this.disabledFn(option);
        } else {
            return false;
        }
    }

    getFieldName(): string {
        return this.field;
    }

    @Input()
    set defaultValue(value: any) {
        // console.log('Set defaultValue to ', value);
        if (value) {
            this._defaultValue = value;
        }
    }

    get defaultValue(): any {
        return this._defaultValue;
    }

    ngOnInit(): void {
        // console.log('ngOnInit started');
        super.ngOnInit();
        this.compareFn = this.compareFn || this.defaultCompareFn;

        if ((this.options$ && this.preSelectFirstOption) || (this.options$ && this.defaultValue)) {
            this.options$.pipe(takeUntil(this._unsubscribeAll)).subscribe((options) => {
                // console.log('Options', options);
                // Preventing defaultValue to be first element from options list
                if (options && options.length > 0 && this._defaultValue !== 'None') {
                    const foundOption = this.defaultValue ? this.isValueExistsInOptions(this.defaultValue, options) : null;
                    if (foundOption != null) {
                        // console.log('Value is in options');
                        if (this.controlValueAttribute) {
                            this.setControlValueIfNotPresent(foundOption[this.controlValueAttribute]);
                        } else {
                            this.setControlValueIfNotPresent(this.defaultValue);
                        }
                    } else if (this.preSelectFirstOption) {
                        this.setControlValueIfNotPresent(this.getValue(options[0]));
                    }
                }
            });
        }
    }

    setControlValueIfNotPresent(value: any): void {
        if (!this._thisControl.value) {
            this._thisControl.setValue(value);
            this.changeDetectorRef.detectChanges();
        }
    }

    isValueExistsInOptions(value: any, options: any): any {
        if (value && options) {
            return options.find((option: any) => {
                if (this.valueAttribute) {
                    return option[this.valueAttribute] === value;
                } else {
                    return typeof option === 'object' && typeof value === 'object' ? option.id === value.id : option === value;
                }
            });
        }
    }

    defaultCompareFn = (c1: any, c2: any): boolean => {
        let isMatch = false;
        if (c1 && c2) {
            if (this.valueAttribute) {
                const firstValue = typeof c1 === 'object' ? c1[this.valueAttribute] : c1;
                const secondValue = typeof c2 === 'object' ? c2[this.valueAttribute] : c2;
                isMatch = firstValue === secondValue;
            } else if (c1 && c2 && typeof c1 === 'object' && typeof c2 === 'object') {
                isMatch = c1.id === c2.id;
            } else {
                isMatch = c1 === c2;
            }
        }
        return isMatch;
    };

    private debouncedLog = debounce((value) => {
        this._logService.log('Selection Change', {
            field: this._fieldPath,
            value: value
        });
    }, 500);

    onChange(item: any): void {
        this.selectionChange.emit(item);
        if (this.enableTracking) {
            this.debouncedLog(item);
        }
    }
}
